import React, { useEffect } from 'react'
import './Test2.css';
import Footer from '../Footer';
import styled from 'styled-components';
import ImageViewerTool from './ImageViewer';

const GetMarginPage = styled.div`
    margin-top: ${props => props.marginTop};
  @media (max-width: 1920px) {
    margin-top: 500px;
    width:95%;
    margin-left:5%;
  }
    @media (max-width: 1024px) {
    margin-top:900px;
        width:95%;
        margin-left:5%;
  }
  @media (max-width: 768px) {
    margin-top:3019px;
  }
`
const GetMarginText = styled.div`
   // margin-top: ${props => props.marginTop};
    width:70%;
    margin-left:15%;
  /* @media (max-width: 1920px) {
    margin-top:-5px;
    width:95%;
    margin-left:5%;
  } */
  @media (max-width: 768px) {
    margin-top:-25px;
    width:95%;
    margin-left:5%;
  }
`
const GetMarginImgBox = styled.div`
    margin-top:20px;
   // margin-top: ${props => props.marginTop};
    width:70%;
    margin-left:15%;
   @media (max-width: 1024px) {
        width:100%;
        margin-left:-5%;
  }
  @media (max-width: 768px) {
    margin-top:25px;
    width:95%;
    margin-left:5%;
;
  }
`

export default function Art() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      {/* <GetMarginPage marginTop="-320px"> */}
      <GetMarginPage marginTop="180px">
        <div className="box">
          <div className="testBox">
            <div className="text-container-title">
              Art
            </div>
            <GetMarginText>
              <div className="text-container-paragraph">
                This represents my art work, traditional and digital.
              </div>
            </GetMarginText>
            <div className="text-container-links">
              <ul>
                <li>
                  <div className=''><a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/mediamanipulationdotcom/'>My instagram profile.</a></div>
                </li>
              </ul>

            </div>
            <GetMarginImgBox>
              <ImageViewerTool height="200px" width="200px" />
            </GetMarginImgBox>

          </div>
        </div>
      </GetMarginPage>
      <Footer />
    </div>
  )
}
