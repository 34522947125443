import React from 'react';

//import { Button } from './Button';
import './HeroSection.css';
import styled from 'styled-components';

const video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;

`
const TagText = styled.div`
  margin-top:700px;
 @media (max-width: 390px) {
       margin-top: 1700px;
       color:black;
  }
`;
const WelcomeText = styled.div`
  color:white;
  font-size:40px;
 @media (max-width: 768px) {
    margin-top:-40px;
    font-size:20px;
  }
   @media (max-width: 390px) {
    margin-top:-300px;
    font-size:20px;
    color:black;
  }
`;

const RedText = styled.div`
  color: red;
  font-size: 70px;
  font-weight: bold;
 @media (max-width: 768px) {
    font-size: 30px;
      margin-top:2px;
  }
`;

const PortfolioTag = styled.div`
  color:white;
  font-size:40px;
  font-weight: bold;
 @media (max-width: 768px) {
       margin-top: 3px;
       font-size:20px;
  }
   @media (max-width: 390px) {
    color:black;
   }
`;

function HeroSection() {
  return (
    <TagText>
      <div className='hero-container'>
        <video src='./videos/video-1.mp4' autoPlay loop muted />
        <WelcomeText>WELCOME TO</WelcomeText>
        <RedText> MEDIAMANIPULATION</RedText>
        <PortfolioTag>Pat McSpadden - Portfolio</PortfolioTag>
      </div>
    </TagText>
  );
}

export default HeroSection;
