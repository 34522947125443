import React from 'react'
import './Test2.css';
import Footer from '../Footer';
import styled from 'styled-components';
import background from "./eye.png";

// import TestAni from './TestAni';

const BGMe = styled.div`
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.5;
    filter: blur(5px);
    @media (max-width: 768px) {
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;

    }
`
const GetMarginPage = styled.div`
    margin-top: ${props => props.marginTop};
       
  @media (max-width: 1920px) {
    margin-top: -200px;
    width:95%;
    margin-left:5%;
  }

  @media (max-width: 768px) {
    margin-top:60px;
  }
    @media (max-width: 390px) {
    margin-top:-140px;
  }
    @media (max-width: 375px) {
    margin-top:-120px;
  }
`
const GetMarginText = styled.div`
   // margin-top: ${props => props.marginTop};
    width:70%;
    margin-left:15%;

  @media (max-width: 768px) {
    margin-top:-25px;
    width:95%;
    margin-left:5%;
;
  }
`
const GetMarginLink = styled.div`
   // margin-top: ${props => props.marginTop};
    width:70%;
    margin-left:15%;
  @media (max-width: 768px) {
    margin-top:10px;
    width:95%;
    margin-left:10%;
  }
 @media (max-width: 390px) {
  margin-left:5%;
  }
`

export default function Development() {
    return (
        <div>
            <BGMe />
            <GetMarginPage marginTop="-220px">
                <div className="box">
                    <div className="testBox">
                        <div className="text-container-title">
                            {/* <TestAni /> */}
                            Development
                        </div>
                        <GetMarginText>
                            <div className="text-container-paragraph">
                                - projects -
                            </div>
                        </GetMarginText>
                        <GetMarginLink>
                            <div className="text-container-links">
                                <ul>
                                    <li>
                                        <div>React Redux Movie Search </div>
                                        <div className=''><a target="_blank" rel="noopener noreferrer" href='https://www.mediamanipulation.com/movie-app-redux/'>React Movie</a></div>
                                    </li>
                                    <li>
                                        <div>React Redux UI App </div>
                                        <div className=''><a target="_blank" rel="noopener noreferrer" href='https://www.mediamanipulation.com/mm-tes/'>Tesla Battery App</a></div>
                                    </li>
                                    <li>
                                        <div>Responsive Material Design demo page </div>
                                        <div className=''><a target="_blank" rel="noopener noreferrer" href='https://www.mediamanipulation.com/tst'>Material Design</a></div>
                                    </li>
                                         <li>
                                        <div>this is an application written Next Js and uses Cypress for Testing.</div>
                                        {/* <div className=''><a target="_blank" rel="noopener noreferrer" href='https://www.mediamanipulation.com/mm-next-app/'>Next Js</a></div> */}
                                        <div className=''><a target="_blank" rel="noopener noreferrer" href='https://github.com/mediamanipulation/next-ca'>Github Repository source</a></div>
                                    </li>
                                    <li>
                                        <div>React 360 virtual reality that Facebook has since sunset.</div>
                                        <div className=''><a target="_blank" rel="noopener noreferrer" href='https://www.mediamanipulation.com/vt/'>React VR 369</a></div>
                                    </li>
                                </ul>
                            </div>
                        </GetMarginLink>
                    </div>
                </div>
            </GetMarginPage >
            <Footer />
        </div >
    )
}


