const tileData = [
    {
        key: '1',
        img: 'https://www.mediamanipulation.com/images/art/mj-01.jpg',
        title: 'midjourney',
    },
    {
        key: '2',
        img: 'https://www.mediamanipulation.com/images/art/mj-02.jpg',
        title: 'midjourney',
    },
    {
        key: '3',
        img: 'https://www.mediamanipulation.com/images/art/mj-03.jpg',
        title: 'midjourney',
    },
    {
        key: '4',
        img: 'https://www.mediamanipulation.com/images/art/mj-04.png',
        title: 'midjourney',
    },
    {
        key: '5',
        img: 'https://www.mediamanipulation.com/images/art/mj-05.png',
        title: 'midjourney',
    },
    {
        key: '6',
        img: 'https://www.mediamanipulation.com/images/art/mj-06.png',
        title: 'midjourney',
    },
    {
        key: '7',
        img: 'https://www.mediamanipulation.com/images/art/mj-07.png',
        title: 'midjourney',
    },
    {
        key: '8',
        img: 'https://www.mediamanipulation.com/images/art/mj-08.png',
        title: 'midjourney',
    },
    {
        key: '9',
        img: 'https://www.mediamanipulation.com/images/art/bl-01.jpg',
        title: 'blender',
    },
    {
        key: '10',
        img: 'https://www.mediamanipulation.com/images/art/bl-02.jpg',
        title: 'blender',
    },
    {
        key: '11',
        img: 'https://www.mediamanipulation.com/images/art/bl-03.jpg',
        title: 'blender',
    },
    {
        key: '12',
        img: 'https://www.mediamanipulation.com/images/art/bl-04.jpg',
        title: 'blender',
    },
    {
        key: '13',
        img: 'https://www.mediamanipulation.com/images/art/bl-05.jpg',
        title: 'blender',
    },
    {
        key: '14',
        img: 'https://www.mediamanipulation.com/images/art/bl-06.jpg',
        title: 'blender',
    },
    {
        key: '15',
        img: 'https://www.mediamanipulation.com/images/img-2-old.jpg',
        title: 'photography',
    },
    {
        key: '16',
        img: 'https://www.mediamanipulation.com/images/art/pho-01.jpg',
        title: 'photography',
    },
    {
        key: '17',
        img: 'https://www.mediamanipulation.com/images/art/pho-02.jpg',
        title: 'photography',
    },
    {
        key: '18',
        img: 'https://www.mediamanipulation.com/images/art/pho-04.png',
        title: 'photography',
    },
    {
        key: '19',
        img: 'https://www.mediamanipulation.com/images/art/pho-05.png',
        title: 'photography',
    },
    {
        key: '20',
        img: 'https://www.mediamanipulation.com/images/art/pnt-01.jpg',
        title: 'painting',
    },
    {
        key: '21',
        img: 'https://www.mediamanipulation.com/images/art/pnt-02.jpg',
        title: 'painting',
    },
    {
        key: '22',
        img: 'https://www.mediamanipulation.com/images/art/pnt-03.jpg',
        title: 'painting',
    },
    {
        key: '23',
        img: 'https://www.mediamanipulation.com/images/art/pnt-04.jpg',
        title: 'painting',
    },
    {
        key: '24',
        img: 'https://www.mediamanipulation.com/images/art/pro-01.jpg',
        title: 'procreate',
    },
    {
        key: '25',
        img: 'https://www.mediamanipulation.com/images/art/pro-02.jpg',
        title: 'procreate',
    },
    {
        key: '26',
        img: 'https://www.mediamanipulation.com/images/art/pro-03.jpg',
        title: 'procreate',
    },
    {
        key: '27',
        img: 'https://www.mediamanipulation.com/images/art/pro-04.jpg',
        title: 'procreate',
    },
    {
        key: '28',
        img: 'https://www.mediamanipulation.com/images/art/pro-05.jpg',
        title: 'procreate',
    },
    {
        key: '29',
        img: 'https://www.mediamanipulation.com/images/art/ue-01.jpg',
        title: 'unreal engine',
    },
    {
        key: '30',
        img: 'https://www.mediamanipulation.com/images/art/ue-02.jpg',
        title: 'unreal engine',
    },
    {
        key: '31',
        img: 'https://www.mediamanipulation.com/images/art/ue-03.jpg',
        title: 'unreal engine',
    },

];

export default tileData;