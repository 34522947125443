
import React, { useEffect } from 'react'
import './Home.css';
import Footer from '../Footer';
import Cards from '../Cards';
import HeroSection from '../HeroSection';


export default function Test2() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <div className="box">
                <div className="testBox">
                    <HeroSection />
                    <Cards />
                </div>
            </div>
            <Footer />
        </div>
    )

}

