
import React from "react";
import { ImageViewer } from "react-image-viewer-dv"
import tileData from './tileData';
import styled from "styled-components";

const ImageViewerToolContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 50%;
    padding: 5px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
    li {
        padding: 10px 0px;
        :first-of-type {
            border-top: none;
        }
    }
`



const ImageViewerTool = (props) => {
    return (
        <div>
            <ImageViewerToolContainer>
                {tileData.map((item) => (
                    <ImageViewer key={item.key}>
                        <img src={item.img} height={props.height} width={props.width} alt={item.title} />
                    </ImageViewer>
                ))}
            </ImageViewerToolContainer>

        </div>
    )
}
export default ImageViewerTool

