import React from 'react'
import './Test2.css';
import Footer from '../Footer';
import styled from 'styled-components';

const GetMarginPage = styled.div`
    margin-top: ${props => props.marginTop};
  @media (max-width: 768px) {
    margin-top:-150px;
  }
`
const GetMarginText = styled.div`
   // margin-top: ${props => props.marginTop};
    width:70%;
    margin-left:15%;
  @media (max-width: 768px) {
    margin-top:-25px;
    width:95%;
    margin-left:5%;
;
  }
`
// const apiKey = "d8f7943fda1b1789b21a16020b2ab390"
export default function Test2() {

    return (
        <div>
            <GetMarginPage marginTop="-420px">
                <div className="box">
                    <div className="testBox">
                        <div className="text-container-title">
                            Development
                        </div>
                        <GetMarginText>
                            <div className="text-container-paragraph">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                                non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </div>
                        </GetMarginText>
                        <div className="text-container-links">
                            <ul>
                                <li>
                                    <div>this repesent text about the app </div>
                                    <div className=''><a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/mediamanipulationdotcom/'>Instagram</a></div>
                                </li>
                                <li>
                                    <div>this repesent text about the app </div>
                                    <div className=''><a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/mediamanipulationdotcom/'>Instagram</a></div>
                                </li>
                                <li>
                                    <div>this repesent text about the app </div>
                                    <div className=''><a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/mediamanipulationdotcom/'>Instagram</a></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </GetMarginPage>
            <Footer />
        </div>
    )
}
