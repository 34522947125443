import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import styled from 'styled-components';

const InfoText = styled.div`
margin-top:-60px;
background-color: white ;
margin-left: 25px;
margin-bottom:50px;
@media only screen and (max-width: 800px) {
  margin-top: -50px;
  background-color: white ;
  margin-left: 25px;
}
@media (max-width: 390px) {

  background-color: white ;
  margin-left: 25px;
}

`;

const InfoText2 = styled.div`
  font-size: 20px;
@media only screen and (max-width: 800px) {
  font-size: 15px;
}
@media (max-width: 390px) {
  background-color: white ;

}
`;

function Cards() {
  return (
    <div className='cards'>

      <div className='cards__container'>
        <div className='cards__wrapper'>
          <InfoText>
            <InfoText2>Hello, this website is an ongoing evolution of technique and design. It showcases my current art and development endeavors. It is built with React, Photoshop, Blender 3.0 and Midjourney A.I.. Please look around and enjoy, there are many updates to follow. Thank you.
            </InfoText2>
          </InfoText>
          <ul className='cards__items'>
            <CardItem
              src='images/art/mj-06.png'
              text='Please check out my artwork.'
              label='midjourney'
              path='/art'
            />
            <CardItem
              src='images/img-2.jpg'
              text='Applications and tools.'
              label='Development'
              path='/development'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/art/pnt-02.jpg'
              text=''
              label='painting'
              path='/art'
            />
            <CardItem
              src='images/img-2-old.jpg'
              text=''
              label='photography'
              path='/art'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/art/pro-02.jpg'
              text=''
              label='procreate'
              path='/art'
            />
            <CardItem
              src='images/art/ue-01.jpg'
              text=''
              label='unreal engine'
              path='/art'
            />
            <CardItem
              src='images/art/pho-02.jpg'
              text=''
              label='photography'
              path='/art'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
