
import React from 'react'
import './Test.css';
import Footer from '../Footer';
import Cards from '../Cards';
import HeroSection from '../HeroSection';



// const apiKey = "d8f7943fda1b1789b21a16020b2ab390"
export default function Test2() {

    return (
        <div>
            <div className="box">
                <div className="testBox">
                    <HeroSection />
                    <Cards />
                </div>
            </div>
            <Footer />
        </div>
    )

}



// import React from 'react'
// import '../../App.css';
// import './Test.css';
// // import Card from '../design-tests/cards/Card';
// import Footer from '../Footer';
// import Grid2 from '../design-tests/grids/Grid2';
// import Flex from '../design-tests/flex/Flex';
// export default function Test() {

//     return (
//         <div>

//             <div className='test'>
//                 {/* <div className="container"> */}
//                 <div className="testConatiner">
//                     <div className='title testItem'>TEST</div>
//                     <Grid2 className="testItem" />
//                     <Flex />
//                 </div>
//                 <div className="gridCont"></div>
//                 {/* </div> */}
//             </div>

//             <Footer />
//         </div>
//     )
// }
