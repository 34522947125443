import React from "react";
import "./Footer.css";
import {
  Box,
} from "./FooterStyles";

const Footer = () => {
  return (
    <div className="footer">
      <Box>
        <h5 style={{
          color: "white",
          textAlign: "center",
        }}>
          mediamanipulation &copy; 1098-2022.
        </h5>
      </Box>
    </div>
  );
};
export default Footer;
